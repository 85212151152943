import './UploadVideo.css';

// REACT
import { useState, useContext, useEffect } from 'react';

// REACT-ROUTER-DOM
import { useNavigate } from 'react-router-dom';

// FIREBASE
import { db } from '../firebase';
import { collection, addDoc, updateDoc, arrayUnion, doc } from 'firebase/firestore';

// CONTEXT
import { VideoContext } from '../context/VideoContext';
import { UserContext } from '../context/UserContext';

// COMPONENTS
import ClientSelection from './UploadVideo/ClientSelection';
import VideoInformation from './UploadVideo/VideoInformation';
import Verify from './UploadVideo/Verify';

const UploadVideo = () => {

    const refreshPage = () => {
        window.location.reload(false);
    }
    
    const navigate = useNavigate();

    // SEND USER BACK IF NOT LOGGED IN
    const [userData] = useContext(UserContext);
    useEffect(() => {
        !userData && navigate('/')
    })

    // GET CONTEXT DATA
    const [uploads, clients] = useContext(VideoContext);

    // SELECTED CLIENT
    const [selectedClient, setSelectedClient] = useState(null);
    const selectedClientHandler = (client) => {
        selectedClient !== client ? setSelectedClient(client) : setSelectedClient(null)
    }

    const [clientDescription, setClientDescription] = useState(null);
    useEffect(() => {
        {
            clients.map((client) => {
                return (
                    client.title === selectedClient &&
                    setClientDescription(client.description)
                );
            })
        }
    }, [selectedClient])

    // VIDEO INFORMATION
    const [addClientTitle, setAddClientTitle] = useState('');
    const [addClientDescription, setAddClientDescription] = useState('');

    const [title, setTitle] = useState('');
    const [url, setUrl] = useState('');
    const [description, setDescription] = useState('');

    const infoChangeHandler = (selectedState, e) => {
        selectedState(e.target.value);
    }

    // NEXT/PREV BUTTONS
    const [prevDisabled, setPrevDisabled] = useState(true);
    const [nextDisabled, setNextDisabled] = useState(true);

    useEffect(() => {
        selectedClient ? setNextDisabled(false) : setNextDisabled(true);
    }, [selectedClient])

    // PAGINATION
    const [currentPage, setCurrentPage] = useState(1);

    const pageHandler = (current, dir) => {
        dir === 'prev' ?
        setCurrentPage(current - 1) :
        current === 3 ?
            uploadVideo()
        :
            setCurrentPage(current + 1);
    }

    useEffect(() => {
        currentPage > 1 ? setPrevDisabled(false) : setPrevDisabled(true)
    })

    // ADD CLIENT
    const clientCollectionRef = collection(db, 'clients');
    const addClient = async () => {
        await addDoc(clientCollectionRef, {
            title: addClientTitle,
            description: addClientDescription,
        }).then(() => {refreshPage();});
    }

    // ADD DOCUMENT TO FIRESTORE
    const uploadCollectionRef = collection(db, 'uploads')
    const uploadVideo = async () => {
        let client = await uploads.find(o => o.client === selectedClient);
        let selectedClientRef = client && doc(db, 'uploads', client.id);
        client ?
        await updateDoc(selectedClientRef, {
            videos: arrayUnion({
                title: title,
                url: url,
                videoDescription: description,
            })
        }).then(() => {navigate('/')}) :
        await addDoc(uploadCollectionRef, {
            client: selectedClient,
            clientDescription: clientDescription,
            videos: [
                {
                    title: title,
                    url: url,
                    videoDescription: description,
                }
            ]
        }).then(() => {
            navigate('/');
            refreshPage();
        });
    }

    // ADD CLIENT MODAL
    const [modalActive, setModalActive] = useState('inactive');
    const toggleModalActive = () => {
        modalActive === 'active' ? setModalActive('inactive') : setModalActive('active');
    }

    useEffect(() => {
        let client = uploads.find(o => o.client === selectedClient);
        console.log(client)
    }, [selectedClient])

    return (
        <div className='upload-video'>
            <div className='form'>

                {
                    currentPage === 1 &&
                    <ClientSelection 
                        clients={clients} 
                        selectedClient={selectedClient} 
                        selectedClientHandler={selectedClientHandler}
                        toggleModalActive={toggleModalActive}
                    />
                }
                {
                    currentPage === 2 &&
                    <VideoInformation 
                        infoChangeHandler={infoChangeHandler} 
                        url={url}
                        setUrl={setUrl}
                        title={title}
                        setTitle={setTitle}
                        description={description}
                        setDescription={setDescription}
                    />
                }
                {
                    currentPage === 3 &&
                    <Verify 
                        selectedClient={selectedClient}
                        clientDescription={clientDescription}
                        url={url}
                        title={title}
                        description={description}
                    />
                }
                <div className={`add-client-modal ${modalActive}`}>
                    <i className='fa-solid fa-times close-modal' onClick={toggleModalActive} ></i>
                    <h2 className='font-title'>Add A New Client</h2>
                    <div className='input-section'>
                        <label htmlFor='client-title'>Client Name:</label>
                        <input type='text' name='client-title' onChange={(e) => {infoChangeHandler(setAddClientTitle, e)}} value={addClientTitle} />
                    </div>
                    <div className='input-section'>
                        <label htmlFor='client-description'>Client Description:</label>
                        <textarea rows='5' name='client-description' onChange={(e) => {infoChangeHandler(setAddClientDescription, e)}} value={addClientDescription} ></textarea>
                    </div>
                    <div className='modal-buttons'>
                        <button onClick={toggleModalActive} >Cancel</button>
                        <button onClick={addClient} >Save Client</button>
                    </div>
                </div>

            </div>
            <div className='next-prev-buttons'>
                <button disabled={prevDisabled} className={`${prevDisabled && 'disabled'}`} onClick={() => {pageHandler(currentPage, 'prev')}}>Back</button>
                <button disabled={nextDisabled} className={`${nextDisabled && 'disabled'}`} onClick={() => {pageHandler(currentPage, 'next')}}>{currentPage > 2 ? 'upload' : 'Next'}</button>
            </div>
        </div>
    );
}

export default UploadVideo;