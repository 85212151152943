// REACT IMPORTS
import { createContext, useState, useEffect } from 'react';

// FIREBASE IMPORTS
import { db } from '../firebase';
import { collection, getDocs } from "firebase/firestore"

export const VideoContext = createContext();

export const VideoProvider = props => {

    // VIDEOS
    const [uploads, setUploads] = useState([]);
    const uploadsCollectionRef = collection(db, "uploads")
  
    useEffect(() => {
      const getUploads = async () => {
        const data = await getDocs(uploadsCollectionRef);
        const uploadData = data.docs.map((doc) => ({...doc.data(), id: doc.id}))
        setUploads(uploadData)
      };
      getUploads()
    }, [])

    // CLIENTS
    const [clients, setClients] = useState([]);
    const clientsCollectionRef = collection(db, "clients")
  
    useEffect(() => {
      const getClients = async () => {
        const data = await getDocs(clientsCollectionRef);
        const clientsData = data.docs.map((doc) => ({...doc.data(), id: doc.id}))
        setClients(clientsData)
      };
      getClients()
    }, [])

        
    return (
        <VideoContext.Provider value={[uploads, clients]}>
            {props.children}
        </VideoContext.Provider>
    );
}