import './UploadLogo.css';

// REACT
import { useState, useEffect } from 'react';

// FIREBASE
import { storage } from '../firebase';
import { ref, uploadBytes, listAll, getDownloadURL, deleteObject } from 'firebase/storage';

import { v4 } from 'uuid';

const UploadLogo = () => {

    const [imageUpload, setImageUpload] = useState(null);
    const [imageList, setImageList] = useState([]);

    const imageListRef = ref(storage, 'images/');

    const uploadImage = () => {
        if (imageUpload == null) return;

        const imageRef = ref(storage, `images/${imageUpload.name + v4()}`);
        uploadBytes(imageRef, imageUpload).then((snapshot) => {
            getDownloadURL(snapshot.ref).then((url) => {
                setImageList((prev) => [...prev, url])
            })
        })
    }

    useEffect(() => {
        listAll(imageListRef).then((res) => {
            res.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setImageList((prev) => [...prev, url]);
                })
            })
        })
    }, [])

    const refreshPage = () => {
        window.location.reload(false);
    }


    const deleteImage = (name) => {
        const imageRef = ref(storage, name);
        deleteObject(imageRef).then(() => {
            refreshPage();
          }).catch((err) => {
            alert(err)
          });
    }

    return (
        <div className="upload-logo">
            <h1>Upload Logo</h1>
            <div className='form'>
                <input type='file' onChange={(e) => {setImageUpload(e.target.files[0])}} />
                <button onClick={uploadImage} >Upload Image</button>
            </div>
            <div className='logo-list'>
            {
                imageList.map((img) => {
                    return (
                        <div className='logo-item'>
                            <img src={img} />
                            <i className='fa-solid fa-trash-can' onClick={() => {deleteImage(img)}}></i>
                        </div>
                    );
                })
            }
            </div>
        </div>
    );
}

export default UploadLogo;