import './LogoDisplay.css';

// REACT
import { useState, useEffect } from 'react';

// FIREBASE
import { storage } from '../firebase';
import { getDownloadURL, listAll, ref } from 'firebase/storage';

// import logo from '../assets/images/logo-mincrea-light.png';

const LogoDisplay = () => {

    const [logoList, setLogoList] = useState([]);

    const logoListRef = ref(storage, 'images/');

    useEffect(() => {
        listAll(logoListRef).then((res) => {
            res.items.forEach((item) => {
                getDownloadURL(item).then((url) => {
                    setLogoList((prev) => [...prev, url]);
                })
            })
        })
    }, [])

    return (
        <div className='logo-display'>
            <div className='logo-list'>
                {/* <div className='logo'>
                    <img src={logo} alt='mincrea logo' className='logo'/>
                </div> */}
                {
                    logoList.map((logo) => {
                        return (
                            <div className='logo'>
                                <img src={logo} alt={logo} className='logo'/>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
}

export default LogoDisplay;