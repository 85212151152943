import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

import { MatchMediaProvider } from './context/MatchMediaContext';
import { VideoProvider } from './context/VideoContext';
import { UserProvider } from './context/UserContext';
import { ContentProvider } from './context/ContentContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <MatchMediaProvider>
        <UserProvider>
            <VideoProvider>
                <ContentProvider>
                    <App />
                </ContentProvider>
            </VideoProvider>
        </UserProvider>
    </MatchMediaProvider>

);
