import './EditPageContent.css';

// REACT-ROUTER-DOM
import { Link } from 'react-router-dom';

const EditPageContent = () => {
    return (
        <>
        <div className="edit-page-content">
            <h1>Edit Page Content</h1>
            <div className='selection'>
                <Link to='/edit-about-page'>
                    <button className='item font-title'>About Mincrea</button>
                </Link>
                <button className='item font-title disabled-link'>Carousel</button>
                <Link to='/upload-logo'>
                    <button className='item font-title'>Client Logo's</button>
                </Link>
            </div>
        </div>
        </>
    );
}

export default EditPageContent;