import './Settings.css';

// REACT IMPORTS
import { useContext, useEffect, useState } from 'react';

// REACT-ROUTER-DOM
import { useNavigate } from 'react-router-dom';

// FIREBASE
import { db } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';

// CONTEXT IMPORTS
import { ContentContext } from '../context/ContentContext';
import { UserContext } from '../context/UserContext';


const Settings = () => {
    
    const navigate = useNavigate();

    const [userData] = useContext(UserContext);
    useEffect(() => {
        !userData && navigate('/')
    })

    const [about, carousel, address] = useContext(ContentContext);

    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [streetname, setStreetname] = useState('');
    const [housenumber, setHousenumber] = useState('');
    const [zipcode, setZipcode] = useState('');
    const [city, setCity] = useState('');

    useEffect(() => {
        setPhone(address.phone);
        setEmail(address.email);
        setStreetname(address.streetname);
        setHousenumber(address.housenumber);
        setZipcode(address.zipcode);
        setCity(address.city);
    }, [address])

    const changeHandler = (stateSetter, value) => {
        stateSetter(value);
    }

    const refreshPage = () => {
        window.location.reload(false);
    }

    const updateContactInformation = async () => {
        let docRef = doc(db, 'page-content', 'address')
        await updateDoc(docRef, {
            phone: phone,
            email: email,
            streetname: streetname,
            housenumber: housenumber,
            zipcode: zipcode,
            city: city,
        }).then(() => {
            navigate('/');
            refreshPage();
        })
    }

    return (
        <div className="settings">
            <h1>Edit Contact Information</h1>
            <div className='address-form'>
                <div className='input-section'>
                    <label htmlFor='phone' >Telefoonnummer</label>
                    <input type='text' name='phone' value={phone} onChange={(e) => changeHandler(setPhone, e.target.value)} />
                </div>
                <div className='input-section'>
                    <label htmlFor='email'>Email</label>
                    <input type='email' name='email' value={email} onChange={(e) => changeHandler(setEmail, e.target.value)} />
                </div>
                <div className='input-section'>
                    <label htmlFor='streetname'>Straatnaam</label>
                    <input type='text' name='streetname' value={streetname} onChange={(e) => changeHandler(setStreetname, e.target.value)} />
                </div>
                <div className='input-section'>
                    <label htmlFor='housenumber'>Huisnummer</label>
                    <input type='text' name='housenumber' value={housenumber} onChange={(e) => changeHandler(setHousenumber, e.target.value)} />
                </div>
                <div className='input-section'>
                    <label htmlFor='zipcode'>Postcode</label>
                    <input type='text' name='zipcode' value={zipcode} onChange={(e) => changeHandler(setZipcode, e.target.value)} />
                </div>
                <div className='input-section'>
                    <label htmlFor='city'>Plaats</label>
                    <input type='text' name='city' value={city} onChange={(e) => changeHandler(setCity, e.target.value)} />
                </div>
                <div className='buttons'>
                    <button onClick={() => {navigate(-1)}}>Cancel</button>
                    <button onClick={updateContactInformation}>Save</button>
                </div>
            </div>
        </div>
    );
}

export default Settings;