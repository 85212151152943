import './VideoOverview.css';

const VideoOverview = () => {
    return (
        <div>
            <h1>Video</h1>
        </div>
    );
}

export default VideoOverview;