import './Contact.css';

// REACT IMPORTS
import { useContext, useEffect } from 'react';

// FIREBASE
import { db } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';

// CONTEXT IMPORTS
import { ContentContext } from '../context/ContentContext';

const Contact = () => {

    const [about, carousel, address] = useContext(ContentContext);

    const handleZipcode = (x) => {
        let y = x?.substr(0, 4);
        let z = x?.substr(-2)
        return `${y}+${z}`;
    }

    return (
        <div className="contact">
            <div className='address'>
                <h1 className='font-title'>Contact</h1>
                <a href={`tel:${address.phone}`}><i className='fa-solid fa-phone'></i> {address.phone}</a>
                <a href={`mailto:${address.email}`}><i className='fa-solid fa-envelope'></i> {address.email}</a>
                <a href={`https://www.google.nl/maps/place/${address.streetname}+${address.housenumber},+${handleZipcode(address.zipcode)}+${address.city}`}>
                    <i className='fa-solid fa-house'></i> {address.streetname} {address.housenumber} {address.zipcode} {address.city}
                </a>
            </div>
        </div>
    );
}

export default Contact;