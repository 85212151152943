import './EditAboutPage.css';

// REACT IMPORTS
import { useContext, useEffect, useState } from 'react';

// REACT-ROUTER-DOM
import { useNavigate } from 'react-router-dom';

// FIREBASE
import { db } from '../firebase';
import { doc, updateDoc } from 'firebase/firestore';

// CONTEXT IMPORTS
import { ContentContext } from '../context/ContentContext';
import { UserContext } from '../context/UserContext';

const EditAboutPage = () => {

    const navigate = useNavigate();

    // Return user if not signed in
    const [userData] = useContext(UserContext);
    useEffect(() => {
        !userData && navigate('/')
    })

    // Get about information from database
    const [about, carousel, address] = useContext(ContentContext);

    // Set states
    const [aboutTitle, setAboutTitle] = useState('');
    const [aboutField, setAboutField] = useState('');

    useEffect(() => {
        setAboutTitle(about.title);
        setAboutField(about.field);
    }, [about])

    const changeHandler = (stateSetter, value) => {
        stateSetter(value);
    }

    const refreshPage = () => {
        window.location.reload(false);
    }

    const updateAboutSection = async () => {
        const docRef = doc(db, 'page-content', 'about');
        await updateDoc(docRef, {
            title: aboutTitle,
            field: aboutField,
        }).then(() => {
            navigate('/');
            refreshPage();
        })
    }

    return (
        <div className="edit-about-page">
            <div className='input-section'>
                <label>About Mincrea Title</label>
                <input type='text' value={aboutTitle} onChange={(e) => {changeHandler(setAboutTitle, e.target.value)}} />
            </div>
            
            <div className='input-section'>
                <label>About Mincrea Text-Field</label>
                <textarea rows='5' value={aboutField} onChange={(e) => {changeHandler(setAboutField, e.target.value)}}></textarea>
            </div>

            <div className='buttons'>
                <button onClick={() => {navigate(-1)}}>Cancel</button>
                <button onClick={updateAboutSection} >Save</button>
            </div>
        </div>
    );
}

export default EditAboutPage;