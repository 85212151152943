import './UploadedVideos.css';

// REACT IMPORTS
import { useContext } from 'react';

// FIREBASE IMPORTS


import { VideoContext } from '../context/VideoContext';
import { UserContext } from '../context/UserContext';

const UploadedVideos = () => {

    const [uploads, clients] = useContext(VideoContext);
    const [userData] = useContext(UserContext);

    return (
        <div className="uploaded-videos page">
            <h1>Uploaded Videos</h1>
            {
                uploads.map((x) => {
                    return(
                        x.videos.map((y) => {
                            return <p>{y.title}</p>
                        })
                    );
                })
            }
        </div>
    );
}

export default UploadedVideos