import { useState, useEffect, createContext } from 'react';

export const MatchMediaContext = createContext();

export const MatchMediaProvider = props => {

    const breakPoint = 910;

    const [matches, setMatches] = useState(
        window.matchMedia(`(min-width: ${breakPoint}px)`).matches
    )

    useEffect(() => {
        window
        .matchMedia(`(min-width: ${breakPoint}px)`)
        .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    return (
        <MatchMediaContext.Provider value={[matches]}>
            {props.children}
        </MatchMediaContext.Provider>
    );
}