import './Navbar.css';

// REACT
import { useState, useContext } from 'react';

// REACT-ROUTER-DOM
import { Link } from 'react-router-dom';

// FIREBASE
import { auth } from '../firebase';
import { signOut } from 'firebase/auth';

// CONTEXT
import { UserContext } from '../context/UserContext';

// ASSETS
import logoLight from '../assets/images/logo-mincrea-light.png';
import logoDark from '../assets/images/logo-mincrea-dark.png';

const Navbar = ({ toggleTheme, theme }) => {

    // USER
    const [userData] = useContext(UserContext);

    const logout = async () => {
        await signOut(auth);
        setAdminActive('inactive')
    }

    // ADMIN MENU
    const [adminActive, setAdminActive] = useState('inactive')
    const adminMenuToggle = () => {
        adminActive === 'active' ? setAdminActive('inactive') : setAdminActive('active')
    }

    return (
        <>
        <nav className="navbar">
            <div className='navbar-fill'>
                { userData && <i className='fa-solid fa-bars' onClick={adminMenuToggle}></i>}
                { userData && <p>logged in as: <span>{userData?.email}</span></p>}
            </div>
            <img src={theme === 'light' ? logoDark : logoLight} alt='' className='logo'/>
            <ul>
                <Link to='/'>
                    <li>Home</li>
                </Link>
                <Link to='/about'>
                    <li>About</li>
                </Link>
                <li onClick={toggleTheme} ><i className='fa-solid fa-lightbulb'></i></li>
            </ul>
        </nav>
        {
        userData &&
        <div className={`admin-menu ${adminActive}`}>
            <ul>
                <Link to='edit-page-content' onClick={adminMenuToggle}>
                    <li>Edit Page Content</li>
                </Link>
                <Link to='/uploads' onClick={adminMenuToggle}>
                    <li className='disabled-link'>Manage Uploads</li>
                </Link>
                <Link to='/upload' onClick={adminMenuToggle}>
                    <li>Add Video</li>
                </Link>
                <Link to='/settings' onClick={adminMenuToggle}>
                    <li>Settings</li>
                </Link>
                <hr/>
                { userData && <li onClick={logout} className='logout'>Logout: {userData?.email}</li> }
            </ul>
        </div>
        }
        </>
    );
}

export default Navbar;