// COMPONENTS
import About from "../components/About";
import Header from "../components/Header";
import Carousel from "../components/Carousel";
import Cases from "../components/Cases";
import Contact from "../components/Contact";
import LogoDisplay from "../components/LogoDisplay";

const Home = () => {
    return (
        <div className="page home">
            <Header />
            <About />
            <Carousel />
            <Cases />
            <LogoDisplay />
            <Contact />
        </div>
    );
}

export default Home;