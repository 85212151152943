import { createContext, useState, useEffect } from 'react';

// FIREBASE AUTH
import { auth } from '../firebase';
import { onAuthStateChanged } from 'firebase/auth';

export const UserContext = createContext();

export const UserProvider = props => {

    const [userData, setUserData] = useState({})
    useEffect(() => {
        onAuthStateChanged(auth, (currentUser) => {
            setUserData(currentUser);
        });
    }, [])

    return (
        <UserContext.Provider value={[userData]}>
            {props.children}
        </UserContext.Provider>
    );
}