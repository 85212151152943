const Verify = ({ selectedClient, clientDescription, url, title, description }) => {
    return (
        <div className='verify'>
            <h2>Verify Entered Information</h2>
            <div className='verification-information'>
                <div className="information-container">
                    <p className="static">Client:</p>
                    <p className="variable">{selectedClient}</p>
                </div>
                <div className="information-container">
                    <p className="static">Client Description:</p>
                    <p className="variable">{clientDescription}</p>
                </div>
                <div className="information-container">
                    <p className="static">Video URL:</p>
                    <p className="variable">{url}</p>
                </div>
                <div className="information-container">
                    <p className="static">Video Title:</p>
                    <p className="variable">{title}</p>
                </div>
                <div className="information-container">
                    <p className="static">Video Description:</p>
                    <p className="variable">{description}</p>
                </div>
            </div>
        </div>
    );
}

export default Verify;