// REACT IMPORTS
import { useState, useEffect } from 'react';

// IMPORTS
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import { auth } from './firebase';

// COMPONENTS
import Navbar from "./components/Navbar";

// PAGES
import Home from "./pages/Home";
import UnderContstruction from './pages/UnderConstruction';
import Video from './pages/Video';
import Login from './pages/Login';
import Upload from './pages/Upload';
import UploadedVideos from './pages/UploadedVideos';
import Case from './pages/Case';
import Settings from './pages/Settings';
import EditPageContent from './pages/EditPageContent';
import EditAboutPage from './pages/EditAboutPage';
import UploadLogo from './pages/UploadLogo';


const App = () => {

  // THEME
  const [theme, setTheme] = useState('dark');
  const toggleTheme = () => {
    theme === 'dark' ? setTheme('light') : setTheme('dark');
  }

  const [showPage, setShowPage] = useState(false);
  const showPageHandler = (code) => {
    code === process.env.REACT_APP_DEVELOPER_CODE ? setShowPage(true) : alert('Entered Wrong Code');
  }

  return (
    <div className="App" data-theme={theme}>
    {
      !showPage ?
      <Router>
      <Navbar toggleTheme={toggleTheme} theme={theme} />
        <Routes>
          <Route path='/' element={ <Home /> } />
          <Route path='/video' element={ <Video /> } />
          <Route path='/login' element={ <Login /> } />
          <Route path='/upload' element={ <Upload /> } />
          <Route path='/uploads' element={<UploadedVideos />} />
          <Route path='/case/:id' element={<Case />} />

          <Route path='/settings' element={<Settings />} />
          <Route path='/edit-page-content' element={<EditPageContent />} />
          <Route path='/edit-about-page' element={<EditAboutPage />} />
          <Route path='/upload-logo' element={<UploadLogo />} />
        </Routes>
      </Router>
      :
      <UnderContstruction showPageHandler={showPageHandler} />
    }
    </div>
  );
}

export default App;
