// REACT IMPORTS
import { useState } from 'react';

const ClientSelection = ({clients, selectedClient, selectedClientHandler, toggleModalActive}) => {
    
    // SEARCHBAR
    const [searchValue, setSearchValue] = useState('');
    const searchValueHandler = (e) => {
        setSearchValue(e.target.value);
    };

    return (
        <>
            <h2 className='font-title'>Select Client</h2>
            <div className='search-container'>
                <input type='text' className='search' onChange={searchValueHandler} value={searchValue} />
                <i className='fa-solid fa-search search-icon'></i>
            </div>
            <div className='client-selection'>
                <div className='client-item add-client' onClick={toggleModalActive} >
                    <p><i className='fa-solid fa-plus'></i> Add New Client</p>
                </div>
                {
                    clients.map((client, i) => {
                        return (
                            searchValue === '' ?
                                <div className={`client-item ${selectedClient === client.title && 'selected-client'}`} onClick={() => {selectedClientHandler(client.title)}} key={i}>
                                    <p>{client.title}</p>
                                    {selectedClient === client.title && <i className='fa-solid fa-check'></i>}
                                </div>
                            :
                                client.title.toLowerCase().replace('\'', '').includes(searchValue.toLowerCase().replace('\'', '')) &&
                                <div className={`client-item ${selectedClient === client.title && 'selected-client'}`} onClick={() => {selectedClientHandler(client.title)}} key={i}>
                                    <p>{client.title}</p>
                                    {selectedClient === client.title && <i className='fa-solid fa-check'></i>}
                                </div>
                        );
                    })
                }
            </div>
        </>
    );
}

export default ClientSelection;