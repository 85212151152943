import { createContext, useEffect, useState } from "react";

import { db } from "../firebase";
import { doc, getDoc } from "firebase/firestore"

export const ContentContext = createContext();

export const ContentProvider = props => {

    const [about, setAbout] = useState({});
    useEffect(() => {
        const getAboutSnap = async () => {
            const aboutRef = doc(db, 'page-content', 'about');
            const aboutSnap = await getDoc(aboutRef);
            setAbout(aboutSnap.data());
        }
        getAboutSnap();
    }, [])

    const [carousel, setCarousel] = useState({});
    useEffect(() => {
        const getCarouselSnap = async () => {
            const carouselRef = doc(db, 'page-content', 'carousel');
            const carouselSnap = await getDoc(carouselRef);
            setCarousel(carouselSnap.data());
        }
        getCarouselSnap();
    })

    const [address, setAddress] = useState({});
    useEffect(() => {
        const getAddressSnap = async () => {
            const addressRef = doc(db, 'page-content', 'address');
            const addressSnap = await getDoc(addressRef);
            setAddress(addressSnap.data());
        }
        getAddressSnap();
    }, [])

    return (
        <ContentContext.Provider value={[about, carousel, address]}>
            {props.children}
        </ContentContext.Provider>
    );
}