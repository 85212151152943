import './Upload.css';

// COMPONENTS
import UploadVideo from "../components/UploadVideo";

const Upload = () => {

    return (
        <div className="upload">
            <h1 className='font-title'>Add a Video</h1>
            <UploadVideo />
        </div>
    );
}

export default Upload;