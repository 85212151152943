const VideoInformation = ({ infoChangeHandler, url, setUrl, title, setTitle, description, setDescription }) => {
    return (
        <div className='video-information'>

            <h2 className='font-title'>Enter Video Information</h2>

            <div className='input-section'>
                <label htmlFor='url'>URL: </label>
                <input type='text' name='url' onChange={(e) => {infoChangeHandler(setUrl, e)}} value={url} />
            </div>

            <div className='input-section'>
                <label htmlFor='title'>Title: </label>
                <input type='text' name='title' onChange={(e) => {infoChangeHandler(setTitle, e)}} value={title} />
            </div>

            <div className='input-section'>
                <label htmlFor='description'>Description: </label>
                <textarea name='description' rows='4' onChange={(e) => {infoChangeHandler(setDescription, e)}} value={description} ></textarea>
            </div>

        </div>
    );
}

export default VideoInformation;