import './UnderConstruction.css';

import { useState } from 'react';

import logo from '../assets/images/logo-mincrea-light.png';

const UnderContstruction = ({ showPageHandler }) => {

    const [modalActive, setModalActive] = useState('inactive');
    const modalActiveHandler = (bool) => {
        bool ? setModalActive('active') : setModalActive('inactive');
    }

    const [input, setInput] = useState('')

    return (
        <div className="construction">
            <img src={logo} alt='' className='logo' />
            <h1 className='font-title'>This website is under construction</h1>
            <p>We'll be back soon</p>
            <button className='developer-entry' onClick={() => {modalActiveHandler(true)}}>Developer?</button>

            <div className={`construction-modal ${modalActive}`}>
                <h1 className='font-title'>Insert Your Code Here</h1>
                <input type='text' onChange={(e) => {setInput(e.target.value)}} />
                <div className='modal-buttons'>
                    <button onClick={() => {modalActiveHandler(false)}}>Cancel</button>
                    <button onClick={() => {showPageHandler(input)}} >Enter Code</button>
                </div>
            </div>
        </div>
    );
}

export default UnderContstruction;