import './Login.css';

// REACT IMPORTS
import { useState, useEffect, useContext } from 'react';

// FIREBASE AUTH
import { auth } from '../firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';

// REACT-ROUTER-DOM
import { useNavigate } from 'react-router-dom';

// CONTEXT
import { UserContext } from '../context/UserContext';

const Login = () => {

    // USER AUTH CHECK && NAVIGATE
    const navigate = useNavigate();
    useEffect(() => {
        userData?.email && navigate('/');
    })

    const [userData] = useContext(UserContext);

    // LOGIN
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const changeHandler = (state, e) => {
        state(e.target.value)
    }

    const loginHandler = async () => {
        try {
            const userLogin = await signInWithEmailAndPassword(auth, email, password);
            console.log(userLogin);
        } catch (error) {
            console.log(error.message)
        }
    }

    // SHOW/HIDE PASSWORD
    const [showPassword, setShowPassword] = useState(false)
    const showPasswordToggle = () => {
        showPassword ? setShowPassword(false) : setShowPassword(true)
    }

    return (
        <div className="login">
            <div className='input-container'>
                <p>{userData?.email}</p>
                <div className='input-component'>
                    <label htmlFor='email'>Enter Email</label>
                    <input type='email' name='email' onChange={e => {changeHandler(setEmail, e)}} />
                </div>

                <div className='input-component'>
                    <label htmlFor='password'>Enter Password</label>
                    <div className='input-password'>
                        <input type={`${showPassword ? 'text' : 'password'}`} name='password' className='password' onChange={e => {changeHandler(setPassword, e)}} />
                        <i className={`fa-solid ${showPassword ? 'fa-eye-slash' : 'fa-eye'} show-password-toggle`} onClick={showPasswordToggle}></i>
                    </div>
                </div>

                <button className='font-title' onClick={loginHandler}>Login</button>

            </div>
        </div>
    );
}

export default Login;