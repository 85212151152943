import './Carousel.css';

// REACT
import { useContext, useEffect } from 'react';

// CONTEXT
import { ContentContext } from '../context/ContentContext';

const Carousel = () => {

    const [about, carousel, address] = useContext(ContentContext);

    useEffect(() => {
        console.log(carousel);
    }, [])

    return (
        <div className='carousel'>
            <div className='carousel-container'>
                <div className='inner-carousel'>
                    {
                        carousel?.items?.map((x, i) => {
                            return (
                                <div className='carousel-item' key={i}>
                                    <h1>{x.title}</h1>
                                    <p>{x.field}</p>
                                    <p className='pagination'>{`${i+1}/${carousel.items.length}`}</p>
                                </div>
                            );
                        })
                    }
                </div>
                {/* <div className='arrows'>
                    <i className='fa-solid fa-chevron-left'></i>
                    <i className='fa-solid fa-chevron-right'></i>
                </div> */}
            </div>
        </div>
    );
}

export default Carousel;