import './About.css';

// REACT
import { useEffect, useContext } from 'react';

// CONTEXT
import { MatchMediaContext } from '../context/MatchMediaContext';
import { ContentContext } from '../context/ContentContext';

import img from '../assets/images/about-mincrea.png';

const About = () => {

    const [about, carousel, address] = useContext(ContentContext);

    return (
        <div className="about">
            <div className="text">
                <h1 className="font-title">{about.title}</h1>
                <p>{about.field}</p>
            </div>
            <div className='image'>
                <img src={img} alt='' />
            </div>
        </div>
    );
}

export default About;