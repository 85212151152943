import VideoOverview from "../components/VideoOverview";

const Video = () => {
    return (
        <div className="page video">
            <VideoOverview />
        </div>
    );
}

export default Video;