import './Case.css';

import { useState, useContext, useEffect } from 'react';

import { useParams } from 'react-router-dom';


import { VideoContext } from '../context/VideoContext';

const Case = () => {

    let { id } = useParams();

    const [uploads, clients] = useContext(VideoContext)

    const [client, setClient] = useState('');

    useEffect(() => {
        console.log(uploads);
        console.log(id)
        console.log(uploads?.find(x => x?.id === id));

        setClient(uploads?.find(x => x?.id === id));
    })

    // Get Youtube ID Function
    const videoLinkHandler = (url) => {
        const id = url?.substr(-11)
        return `https://www.youtube.com/embed/${id}`
    }
    
    
    return (
        <div className="case">
            <div className='container'>
                <h1>{client?.client}</h1>
                <p className='client-description'>{client?.clientDescription}</p>
                <div className='case-videos'>
                {
                    client?.videos?.map((video, i) => {
                        return (
                            <div className='item'>
                                <iframe 
                                    src={videoLinkHandler(video?.url)} 
                                    title={video?.title} 
                                    frameBorder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen">
                                </iframe>
                                <div className='video-information'>
                                    <h2 className='video-title font-title'>{video.title}</h2>
                                    <p className='video-description'>{video.videoDescription}</p>
                                </div>
                            </div>
                        );
                    })
                }
                </div>
            </div>
        </div>
    );
}

export default Case;