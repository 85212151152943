import './Header.css';

import { useState, useRef, useEffect, useContext } from 'react';

import { MatchMediaContext } from '../context/MatchMediaContext';

import video from '../assets/videos/mincrea-trailer.mp4';

const Header = () => {

    // IS BIG SCREEN?
    const [matches] = useContext(MatchMediaContext);

    const [autoPlayActive, setAutoPlayActive] = useState(true);
    useEffect(() => {
        matches ? setAutoPlayActive(true) : setAutoPlayActive(false)
    })

    // VIDEO, AUTOPLAY
    const videoRef = useRef();
    useEffect(() => {
        matches && videoRef.current.play();
    }, [])

    return (
        <div className='header'>
            <video ref={videoRef} className='header-video' loop muted controls={!autoPlayActive} autoPlay={autoPlayActive}>
                <source src={video} type='video/mp4' />
            </video>
        </div>
    );
}

export default Header;