import './Cases.css';

// REACT IMPORTS
import { useContext, useEffect, useState } from 'react';

// REACT-ROUTER-DOM
import { Link } from 'react-router-dom';

// FIREBASE IMPORTS
import { db } from '../firebase';
import { arrayRemove, arrayUnion, doc, getDoc, updateDoc } from 'firebase/firestore';

// CONTEXT
import { VideoContext } from '../context/VideoContext';
import { UserContext } from '../context/UserContext';

const Cases = () => {

    // Get Youtube ID Function
    const videoLinkHandler = (url) => {
        const id = url?.substr(-11)
        return `https://www.youtube.com/embed/${id}`
    }

    // Get VideoContext
    const [videos, clients] = useContext(VideoContext);

    // Amount of videos shown in cases section
    const [showVideoAmount, setShowVideoAmount] = useState(2);
    const showMoreVideos = (val) => {
        val === 'add' ? setShowVideoAmount(showVideoAmount + 2) : setShowVideoAmount(showVideoAmount - 2);
    }

    // USERDATA
    const [userData] = useContext(UserContext);

    // UPDATE VIDEO MODAL
    const [updateVideoModalActive, setUpdateVideoModalActive] = useState('inactive');
    const [selectedVideo, setSelectedVideo] = useState({});

    const [videoTitle, setVideoTitle] = useState('');
    const [videoURL, setVideoURL] = useState('');
    const [videoDescription, setVideoDescription] = useState('');
    const [videoIndex, setVideoIndex] = useState(0);

    const toggleUpdateVideoModal = async (active, video, i) => {
        setUpdateVideoModalActive(active);
        const ref = doc(db, 'uploads', video);
        const docSnap = await getDoc(ref);
        if(docSnap.exists()) {
            setSelectedVideo({...docSnap.data(), id: docSnap.id});
            setVideoIndex(i)
            setVideoTitle(docSnap.data().videos[i].title);
            setVideoURL(docSnap.data().videos[i].url);
            setVideoDescription(docSnap.data().videos[i].videoDescription)
        } else {
            console.log('no such document');
        }
    }

    const changeHandler = (val, changeState) => {
        changeState(val);
    }

    const updateVideoHandler = async (i) => {
        let docRef = doc(db, 'uploads', selectedVideo?.id);
        await updateDoc(docRef, {
            videos: arrayUnion({
                title: videoTitle,
                url: videoURL,
                videoDescription: videoDescription,
            })
        })
        await updateDoc(docRef, {
            videos: arrayRemove(selectedVideo?.videos[i])
        });
        setUpdateVideoModalActive('inactive');
    }


    return (
        <div className='cases'>
            <h1 className='font-title title'>Projecten</h1>
            <div className='cases-container'>
                {
                    videos?.map((x, i) => {
                        return (
                            i < showVideoAmount && x.videos.length > 0 &&
                            <Link to={`/case/${x?.id}`} className='case-item' key={`${x?.title}-${i}`}>
                                <h1>{x?.client}</h1>
                                <p>{x?.videos[0]?.title}</p>
                                <iframe 
                                    src={videoLinkHandler(x?.videos[0]?.url)} 
                                    title={x?.videos[0]?.title} 
                                    frameBorder="0" 
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen">
                                </iframe>
                                <p className='item-description'>
                                    {x?.clientDescription?.substr(0,200)}
                                    {x?.clientDescription?.length > 200 && '...' }
                                </p>
                                <button className='font-title'>Read More</button>
                                {/* {
                                    userData?.uid === process.env.REACT_APP_ADMIN_UID &&
                                    <button className='edit-button' onClick={() => {toggleUpdateVideoModal('active', x?.id, 0)}}>
                                        <i className='fa-solid fa-cog'></i>
                                    </button>
                                } */}
                            </Link>
                        );
                    })
                }
            </div>
            <div className='show-more-videos'>
            {
                videos.length > showVideoAmount ?
                <button className='show-more-button font-title' onClick={() => {showMoreVideos('add')}}>Laat meer zien</button> :
                <button className='show-more-button font-title' onClick={() => {showMoreVideos('remove')}}>Laat minder zien</button>
            }
            </div>
            {/* <div className={`update-video-modal ${updateVideoModalActive}`}>
                <div className='form'>
                    <i className='fa-solid fa-times close-icon' onClick={() => {toggleUpdateVideoModal('inactive')}} />
                    <div className='input-section'>
                        <label>Edit Title</label>
                        <input type='text' value={videoTitle} onChange={(e) => {changeHandler(e.target.value, setVideoTitle)}} />
                    </div>
                    <div className='input-section'>
                        <label>Edit URL</label>
                        <input type='text' value={videoURL} onChange={(e) => {changeHandler(e.target.value, setVideoURL)}} />
                    </div>
                    <div className='input-section'>
                        <label>Edit Description</label>
                        <textarea type='text' rows='5' value={videoDescription} onChange={(e) => {changeHandler(e.target.value, setVideoDescription)}} ></textarea>
                    </div>
                    <div className='buttons'>
                        <button onClick={() => {toggleUpdateVideoModal('inactive')}}>Cancel</button>
                        <button onClick={() => {updateVideoHandler(videoIndex)}} >Save</button>
                    </div>
                </div>
            </div> */}
        </div>
    );
}

export default Cases;